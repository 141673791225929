







































































































import Vue from 'vue';
import moment from 'moment';
import { message } from 'ant-design-vue';
import * as couponApi from '@/api/couponTemplate';
export default Vue.extend({
  name: 'selectCoupon',
  props: [],
  data() {
    return {
      showModal: false,
      modalTitle: '选择优惠券',
      tableLoading: false,
      searchForm: {
        name: '', // 优惠券名称
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      couponTemplateColumns: [
        {
          title: '优惠券名称',
          dataIndex: 'name',
          align: 'center',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '满减(元)/折扣',
          dataIndex: 'strategyStr',
          align: 'center',
        },
        // {
        //   title: '使用范围',
        //   dataIndex: 'availableAppStr',
        //   align: 'center',
        // },
        {
          title: '有效期',
          dataIndex: 'expiredType',
          align: 'center',
          scopedSlots: { customRender: 'expiredType' },
        },
      ],
      couponTemplateData: [] as any,
      selectedRowKeys: [], //选中的数据key
      selectedRows: [], //选中的数据
    };
  },
  methods: {
    moment,
    show() {
      this.showModal = true;
      this.getCouponTemplateList();
    },
    // 获取优惠券模板列表
    getCouponTemplateList() {
      this.tableLoading = true;
      couponApi
        .getCouponTemplateList(
          this.searchForm.name,
          '1',
          this.pagination.current,
          this.pagination.pageSize
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.couponTemplateData = res.result.list;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getCouponTemplateList();
    },
    // 组合查询表单重置
    handleSearchReset() {
      (this.$refs.searchFormRef as HTMLFormElement).resetFields();
      this.pagination.current = 1;
      this.getCouponTemplateList();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getCouponTemplateList();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    handleFormCancel() {
      this.showModal = false;
      this.clearSelectdRows();
    },
    handleModalCancel() {
      this.clearSelectdRows();
    },
    handleFormSave() {
      if (this.selectedRows.length) {
        this.$emit('selectCouponSuccess', this.selectedRows);
        this.showModal = false;
        this.clearSelectdRows();
      } else {
        message.error('请选择优惠券！');
      }
    },
    //清空已选择
    clearSelectdRows() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
  },
});
