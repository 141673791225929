var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transfer-modal-wrap"},[_c('a-modal',{attrs:{"title":"选择租户","maskClosable":false,"destroyOnClose":"","width":"1000px"},on:{"ok":_vm.handleOk},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('a-transfer',{attrs:{"show-search":"","titles":['来 源', '目 标'],"filter-option":function (inputValue, item) { return item.enterpriseName.indexOf(inputValue) !== -1; },"data-source":_vm.transferData,"target-keys":_vm.targetKeys,"show-select-all":false},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"children",fn:function(ref){
        var ref_props = ref.props;
        var direction = ref_props.direction;
        var filteredItems = ref_props.filteredItems;
        var selectedKeys = ref_props.selectedKeys;
        var listDisabled = ref_props.disabled;
        var ref_on = ref.on;
        var itemSelectAll = ref_on.itemSelectAll;
        var itemSelect = ref_on.itemSelect;
return [_c('a-table',{style:({ pointerEvents: listDisabled ? 'none' : null }),attrs:{"rowKey":"agencyCode","scroll":{ y: 240 },"row-selection":_vm.getRowSelection({
              disabled: listDisabled,
              selectedKeys: selectedKeys,
              itemSelectAll: itemSelectAll,
              itemSelect: itemSelect,
            }),"pagination":false,"columns":direction === 'left' ? _vm.leftColumns : _vm.rightColumns,"data-source":filteredItems,"size":"small","custom-row":function (ref) {
                    var key = ref.key;
                    var itemDisabled = ref.disabled;

                    return ({
              on: {
                click: function () {
                  if (itemDisabled || listDisabled) { return; }
                  itemSelect(key, !selectedKeys.includes(key));
                },
              },
            });
        }}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }