















































































































































































































































import Vue from 'vue';
import moment from 'moment';
import { message } from 'ant-design-vue';
import * as api from '@/api/couponDistribute';
import selectCoupon from './selectCoupon/index.vue';
import selectTenant from './selectTenant/index.vue';
const initForm = {
  sequenceNbr: '', //唯一流水号
  sendType: '', //发送类型，0：全部，1：按租户，2按条件
  sendStatus: 0, //发送状态，0：草稿，1：派发
  couponDefinitionId: '', //存储优惠券id
  sendRange: '', //存储发送范围(全部)（选'全部时'赋值all,否则为''）
  agencyParamDTOList: [], //存储发送范围(租户)
  agencyQueryDTO: [
    {
      where: 'PayCount',
      symbol: 'equals',
      value: undefined,
      relation: 'no',
      valueType: 'number',
    },
  ], //存储发送范围(条件)
};
export default Vue.extend({
  name: 'createDistribute',
  components: { selectCoupon, selectTenant },
  props: [],
  data() {
    return {
      showModal: false,
      modalTitle: '新建',
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      couponName: '', //优惠券名
      tenantName: '', //租户名
      allChecked: false, // 发送范围（全部）
      distributeForm: {} as any,
      distributeFormRules: {
        couponDefinitionId: [
          {
            required: true,
            message: '请选择优惠券',
          },
        ],
        sendType: [
          {
            required: true,
            message: '请选择发送范围(类型)',
          },
        ],
        agencyParamDTOList: [
          {
            required: true,
            message: '请选择租户',
          },
        ],
      },
      formLoading: false, //表单加载状态
      buttonLoading: false, //按钮加载状态
      whereList: [
        { label: '已支付次数', value: 'PayCount', valueType: 'number' },
        {
          label: '最后登录时间',
          value: 'LastLoginTime',
          valueType: 'dateTime',
        },
      ],
      symbolList: [
        { label: '=', value: 'equals' },
        // { label: '!=', value: 'notEquals' },
        { label: '>', value: 'gt' },
        { label: '>=', value: 'gte' },
        { label: '<', value: 'lt' },
        { label: '<=', value: 'lte' },
      ],
      conditionRelationList: [
        { label: '复合条件', value: 'no' },
        { label: '并且', value: 'and' },
        { label: '或者', value: 'or' },
      ],
      transferTargetKeys: [],
    };
  },
  methods: {
    moment,
    show() {
      this.modalTitle = '新建';
      this.showModal = true;
      this.distributeForm = { ...initForm };
      console.log(this.distributeForm);
      this.couponName = '';
      this.tenantName = '';
      this.transferTargetKeys = [];
    },
    edit(record) {
      // console.log('edit:', record);
      this.modalTitle = '编辑';
      this.showModal = true;
      this.distributeForm = { ...initForm };
      this.couponName = '';
      this.tenantName = '';
      this.transferTargetKeys = [];
      this.formLoading = true;
      api
        .getDistributeDetail(record.sequenceNbr)
        .then((res) => {
          if (res.status === 200) {
            this.initBasicData(res.result);
          }
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    // 初始化基础数据
    initBasicData(record) {
      this.couponName = record.name;
      this.distributeForm = {
        sequenceNbr: record.sequenceNbr,
        sendType: record.sendType,
        sendStatus: record.sendStatus,
        couponDefinitionId: record.couponDefinitionId,
        sendRange: record.sendRange,
        agencyParamDTOList: [],
        agencyQueryDTO: initForm.agencyQueryDTO,
      };

      if (record.sendType === 1 && record.sendRange) {
        this.tenantName = record.sendRangeInfo;
        this.transferTargetKeys = record.sendRange.split(',');
        this.distributeForm.agencyParamDTOList = record.agencyParamDTOList;
      }

      if (record.sendType === 2 && record.sendRange) {
        this.distributeForm.agencyQueryDTO = this.domainDataToWeb(
          JSON.parse(record.sendRange)
        );
      }
    },
    //打开选择优惠券弹窗
    handleSelectCoupon() {
      (this.$refs.selectCoupon as HTMLFormElement).show();
    },
    //打开选择租户弹窗
    handleSelectTenant() {
      (this.$refs.selectTenant as HTMLFormElement).show();
    },
    //优惠券选择成功事件
    selectCouponSuccess(selectedRows) {
      const currentData = selectedRows[0];
      const { name = '', sequenceNbr = '' } = { ...currentData };
      this.couponName = name;
      this.distributeForm.name = name;
      this.distributeForm.couponDefinitionId = sequenceNbr;
      (this.$refs.distributeForm as HTMLFormElement).validateField(
        'couponDefinitionId'
      );
    },
    //租户选择成功事件
    selectTenantSuccess(selectedRows) {
      const currentData = selectedRows.targetRows;
      this.distributeForm.agencyParamDTOList = currentData.map((item) => {
        return {
          agencyCode: item.agencyCode,
          enterpriseName: item.enterpriseName,
        };
      });
      this.tenantName = currentData
        .map((item) => item.enterpriseName)
        .join(',');
      (this.$refs.distributeForm as HTMLFormElement).validateField(
        'agencyParamDTOList'
      );
    },
    //发送范围(类型)切换事件
    sendTypeChange(e) {
      const value = e.target.value;
      this.distributeForm.sendRange = value === 0 ? 'ALL' : '';
      this.tenantName = '';
      this.distributeForm.agencyParamDTOList = [];
      this.distributeForm.agencyQueryDTO = [
        {
          where: 'PayCount',
          symbol: 'equals',
          value: undefined,
          relation: 'no',
          valueType: 'number',
        },
      ];
    },
    //关联条件change事件
    relationChange(index, value) {
      //   console.log(index, value);
      if (
        value !== 'no' &&
        index === this.distributeForm.agencyQueryDTO.length - 1
      ) {
        this.distributeForm.agencyQueryDTO.push({
          where: 'PayCount',
          symbol: 'equals',
          value: undefined,
          relation: 'no',
          valueType: 'number',
        });
      }
    },
    //where条件改变事件
    whereChange(index, value) {
      this.distributeForm.agencyQueryDTO[index].value = undefined; // 清空值
      const item = this.whereList.find((item) => item.value === value); // 从whereList获取对应项
      this.distributeForm.agencyQueryDTO[index].valueType = item?.valueType; //更改对应项valueType
    },
    //获取当前输入项组件类型
    getConditionValueType(item) {
      const currentWhere = item.where;
      if (item.where) {
        const item = this.whereList.find((item) => item.value === currentWhere);
        return item?.valueType;
      } else {
        return 'string';
      }
    },
    //移除当前行条件
    removeDomain(index) {
      const currentLength = this.distributeForm.agencyQueryDTO.length;
      this.distributeForm.agencyQueryDTO.splice(index, 1);
      if (index === currentLength - 1) {
        // 如果删除最后一行条件，则将上一行条件的关系设置为 复合条件
        this.distributeForm.agencyQueryDTO[index - 1].relation = 'no';
      }
    },
    //domain数据格式转换toWeb
    domainDataToWeb(data) {
      let noArr = [] as any;
      let currentData = [
        {
          where: data.where,
          symbol: data.symbol,
          value: data.value,
          relation: data.relation,
          valueType: data.valueType,
        },
      ];
      if (data.and) {
        // currentData = [...currentData, ...data.and];
        for (let item of data.and) {
          if (item.relation === 'no') {
            noArr.push(item);
          } else {
            currentData.push(item);
          }
        }
      }
      if (data.or) {
        // currentData = [...currentData, ...data.or];
        for (let item of data.or) {
          if (item.relation === 'no') {
            noArr.push(item);
          } else {
            currentData.push(item);
          }
        }
      }

      currentData = [...currentData, ...noArr];
      console.log('domainDataToWeb:', currentData);
      return currentData;
    },
    //domain数据格式转换toServer
    domainDataToServer(data) {
      let currentData = { ...data[0] };
      if (data.length !== 1) {
        let andArr = [] as any;
        let orArr = [] as any;
        for (let index = 0; index < data.length; index++) {
          const relation = data[index].relation;
          if (relation === 'and') {
            andArr.push({ ...data[index + 1] });
          }
          if (relation === 'or') {
            orArr.push({ ...data[index + 1] });
          }
        }
        currentData.and = andArr;
        currentData.or = orArr;
      }
      console.log('domainDataToServer:', currentData);
      return currentData;
    },
    handleFormCancel() {
      this.showModal = false;
      (this.$refs.distributeForm as HTMLFormElement).resetFields();
    },
    handleModalCancel() {
      (this.$refs.distributeForm as HTMLFormElement).resetFields();
    },
    handleFormSave(sendStatus) {
      (this.$refs.distributeForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          this.addCouponSendRecord(sendStatus);
        }
      });
    },
    addCouponSendRecord(sendStatus) {
      const agencyQueryDTO = this.domainDataToServer(
        this.distributeForm.agencyQueryDTO
      );
      this.buttonLoading = true;
      api
        .addCouponSendRecord({
          ...this.distributeForm,
          sendStatus: sendStatus,
          agencyQueryDTO: agencyQueryDTO,
        })
        .then((res) => {
          if (res.status === 200) {
            message.success('操作成功！');
            this.handleFormCancel();
            this.$emit('formSaveSuccess');
          }
        })
        .finally(() => {
          this.buttonLoading = false;
        });
    },
  },
});
