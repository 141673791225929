










































































import Vue from 'vue';
import * as api from '@/api/couponDistribute';
import difference from 'lodash/difference';
const leftTableColumns = [
  {
    dataIndex: 'enterpriseName',
    title: '租户名称',
  },
  {
    dataIndex: 'applySource',
    title: '用户来源',
  },
];
const rightTableColumns = [
  {
    dataIndex: 'enterpriseName',
    title: '租户名称',
  },
  {
    dataIndex: 'applySource',
    title: '用户来源',
  },
];
export default Vue.extend({
  props: {
    transferTargetKeys: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
      listLoading: false,
      targetKeys: [],
      transferData: [],
      leftColumns: leftTableColumns,
      rightColumns: rightTableColumns,
    };
  },
  watch: {
    transferTargetKeys(val) {
      if (val) {
        this.targetKeys = val;
      }
    },
  },
  methods: {
    show() {
      this.showModal = true;
      this.getAllTransferData();
    },
    getAllTransferData() {
      //查询穿梭框中的所有数据
      this.listLoading = true;
      api
        .queryAllAgenciesSimpleInfo()
        .then((res) => {
          if (res.status === 200) {
            this.transferData = res.result.map((item) => {
              return {
                ...item,
                key: item.agencyCode,
                disabled: false,
                description: '',
              };
            });
          }
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    getAddIds(a, b) {
      return a.filter((item) => {
        return b.indexOf(item) === -1;
      });
    },
    getDeleteIds(a, b) {
      return b.filter((item) => {
        return a.indexOf(item) === -1;
      });
    },
    getTargetRows(targetKeys) {
      return this.transferData.filter((item: any) => {
        return targetKeys.indexOf(item.agencyCode) != -1;
      });
    },
    handleOk() {
      //获取目标增项
      const addIds = this.getAddIds(this.targetKeys, this.transferTargetKeys);

      //获取目标减项
      const deleteIds = this.getDeleteIds(
        this.targetKeys,
        this.transferTargetKeys
      );

      //获取目标全项
      const targetRows = this.getTargetRows(this.targetKeys);

      const currentData = {
        addIds: addIds,
        deleteIds: deleteIds,
        targetRows: targetRows,
      };

      //   console.log('addIds', addIds);
      //   console.log('deleteIds', deleteIds);
      //   console.log('targetRows', targetRows);
      //   console.log('currentData', currentData);
      this.showModal = false;
      this.$emit('selectTenantSuccess', currentData);
    },
    onChange(nextTargetKeys) {
      this.targetKeys = nextTargetKeys;
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: (item) => ({
          props: { disabled: disabled || item.disabled },
        }),
        onSelectAll(selected, selectedRows) {
          // console.log(selected);
          // console.log(selectedRows);

          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },
  },
});
