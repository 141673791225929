/*
 * @Descripttion:优惠券发布模块接口文件
 * @Author: yuyang
 * @Date: 2022-03-25 10:27:10
 * @LastEditors: yuyang
 * @LastEditTime: 2022-05-10 16:06:40
 */
import request from '@/utils/request';
import { BaseResponse } from '@/interface';

// 列表查询
export function getCouponDistributeList(
  name: string,
  sendStatus: string,
  page: number,
  limit: number
): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizCouponSendRecord`,
    method: 'GET',
    params: {
      name,
      sendStatus,
      page,
      limit,
    },
  });
}

// 新增数据
export function addCouponSendRecord(data): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizCouponSendRecord`,
    method: 'POST',
    data,
  });
}

// 详情查询
export function getDistributeDetail(id: string): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizCouponSendRecord/${id}`,
    method: 'GET',
  });
}

// 派发
export function releaseDistributeRecords(data): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizCouponSendRecord/couponSend`,
    method: 'POST',
    data,
  });
}

// 租户列表查询
export function queryAllAgenciesSimpleInfo(): Promise<BaseResponse> {
  return request({
    url: `/agency-resource/1/agencies/queryAllAgenciesSimpleInfo`,
    method: 'GET',
  });
}
