/*
 * @Descripttion:
 * @Author: yuyang
 * @Date: 2022-03-24 15:16:56
 * @LastEditors: yuyang
 * @LastEditTime: 2022-05-21 11:26:55
 */
import request from '@/utils/request';
import { BaseResponse } from '@/interface';

// 列表查询
export function getCouponTemplateList(
  name: string,
  status: string,
  page: number,
  limit: number
): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizCouponDefinition/queryByPage`,
    method: 'GET',
    params: {
      name,
      status,
      page,
      limit,
    },
  });
}

// 新增优惠券模板
export function addCouponRecords(data): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizCouponDefinition`,
    method: 'POST',
    data,
  });
}

// 编辑优惠券模板
export function editCouponRecords(data): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizCouponDefinition`,
    method: 'PUT',
    data,
  });
}

// 优惠券模板详情查询
export function getCouponRecordsDetail(id: string): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizCouponDefinition/${id}`,
    method: 'GET',
  });
}

// 触发条件列表查询
export function getSysEventList(eventType: string): Promise<BaseResponse> {
  return request({
    url: `/sys-resource/1/sysEventConfigResourceV1`,
    method: 'GET',
    params: {
      eventType,
    },
  });
}

//应用下拉列表查询
export function getApplicationList(
  bizStatus: number | null
): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/application/list`,
    method: 'GET',
    params: {
      bizStatus: bizStatus,
    },
  });
}

// 发布优惠券
export function releaseCouponRecords(
  sequenceNbr: string
): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizCouponDefinition/release/${sequenceNbr}`,
    method: 'POST',
  });
}

// 作废优惠券
export function discardCouponRecords(
  sequenceNbr: string
): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizCouponDefinition/discard/${sequenceNbr}`,
    method: 'POST',
  });
}

// 下架优惠券
export function offCouponRecords(sequenceNbr: string): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizCouponDefinition/unRelease/${sequenceNbr}`,
    method: 'POST',
  });
}

// 适用范围树形结构查询
export function getTreeData(param?: string): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/productSpu/buildTree`,
    method: 'GET',
    params: {
      param,
    },
  });
}

// 获取适用范围信息（id转文字信息）
export function getSkuIdInfo(skuIds: string): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizDiscountDefinitionUseScope/selectAllBySkuId`,
    method: 'GET',
    params: {
      skuIds,
    },
  });
}

// 查询优惠券详情数据表格
export function getCouponDetailTableData(
  sequenceNbr: string,
  agencyName: string,
  source: string,
  hasUsed: string,
  page: number,
  limit: number
): Promise<BaseResponse> {
  return request({
    url: `/marketing-resource/1/bizCouponR/couponSendList`,
    method: 'GET',
    params: {
      sequenceNbr,
      agencyName,
      source,
      hasUsed,
      page,
      limit,
    },
  });
}
