var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"title":_vm.modalTitle,"maskClosable":false,"footer":null,"width":"1000px","destroyOnClose":""},on:{"cancel":_vm.handleModalCancel},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"search"},[_c('a-form-model',{ref:"searchFormRef",attrs:{"layout":"inline","model":_vm.searchForm}},[_c('a-form-model-item',{attrs:{"label":"优惠券名称","prop":"name"}},[_c('a-input',{attrs:{"placeholder":"请输入优惠券名称"},model:{value:(_vm.searchForm.name),callback:function ($$v) {_vm.$set(_vm.searchForm, "name", $$v)},expression:"searchForm.name"}})],1),_c('a-form-model-item',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSearchSubmit}},[_vm._v(" 查询 ")]),_c('a-button',{staticStyle:{"margin-left":"10px"},on:{"click":_vm.handleSearchReset}},[_vm._v(" 重置 ")])],1)],1)],1)]),_c('a-table',{attrs:{"rowKey":"sequenceNbr","columns":_vm.couponTemplateColumns,"data-source":_vm.couponTemplateData,"scroll":{ x: 'max-content' },"pagination":_vm.pagination,"loading":_vm.tableLoading,"row-selection":{
        type: 'radio',
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
        getCheckboxProps: function (record) {
          return {
            props: {
              disabled: false,
            },
          };
        },
      }},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"name",fn:function(text, record){return [(record.extend1 === '1')?_c('a-tag',{attrs:{"color":"#f50"}},[_vm._v("H5")]):_vm._e(),_vm._v(" "+_vm._s(text)+" ")]}},{key:"expiredType",fn:function(text, record){return [(text === 0)?_c('span',[_vm._v(_vm._s(record.validDay)+" 天")]):_vm._e(),(text === 1)?_c('span',[_vm._v(" "+_vm._s(record.startTime ? _vm.moment(record.startTime).format('YYYY-MM-DD HH:mm:ss') : '')+" ~ "+_vm._s(record.endTime ? _vm.moment(record.endTime).format('YYYY-MM-DD HH:mm:ss') : '')+" ")]):_vm._e()]}}])}),_c('div',{staticClass:"modalFooterTool"},[_c('a-button',{on:{"click":_vm.handleFormCancel}},[_vm._v("取消")]),_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary"},on:{"click":_vm.handleFormSave}},[_vm._v(" 确定 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }